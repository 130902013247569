<template>
  <div class="mobile-ticket">
    <div class="mobile-ticket-title">
      <h4>My Tickets</h4>
      <button class="lab-btn lab-btn-info ml-1" @click="$router.push({ name: 'new-ticket' })">Open Ticket</button>
    </div>
    
    <div class="mobile-ticket-body">
      <div class="dashboard-card mobile-dashboard-item" v-for="item in tickets" :key="item.id">
        <div class="item-line">
          <div class="item-info">
            <h5>Ticket ID</h5>
            <h6>{{item.ticketNo}}</h6>
          </div>
          <div class="item-info">
            <h5>Status</h5>
            <span v-if="item.isClosed">
              <span class="lab-badge lab-danger">Closed</span>
            </span>
            <span v-else>
              <span v-show="item.ticketStatus === 0 && item.isRead === false" class="lab-badge lab-pale-marigold">Pending</span>
              <span v-show="item.ticketStatus === 1" class="lab-badge lab-badge-mellow-melon">New Message</span>
            </span>
          </div>
        </div>
        <div class="item-line">
          <div class="item-info">
            <h5>Date</h5>
            <h6>{{item.createdDateLabel}}</h6>
          </div>
          <div class="item-info text-right">
            <span  @click="$router.push({ name: 'ticket-detail', params: { id: item.ticketNo } })"><circle-arrow-right-icon class="cursor-pointer"/></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleArrowRightIcon from '@/layouts/icons/CircleArrowRightIcon.vue';

export default {
  components: {
    CircleArrowRightIcon,
  },
  data() {
    return {
    }
  },
  methods: {
    fetchTickets() {
      this.$store.dispatch('ticket/fetchTickets', {});
    },
  },
  computed: {
    tickets() {
      return this.$store.getters['ticket/getTicketList'];
    },
  },
  created() {
    this.fetchTickets();
  },
};
</script>

<style scoped lang="scss">
.mobile-ticket-title {
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
